<template>
  <div class="main" :style="{ 'opacity': getTestData ? 1 : 0 }">
    <header-t />
    <nav2 />
    <div class="cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="item in crumbs" :key="item.categoryId"><a :href="item.urlParams">{{
    item.categoryName
  }}</a></el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-row v-if="!isPurchased" class="class-item">
          <el-col :span="10">
            <div class="img-wrap">
              <img :src="coursePic" alt="" />
            </div>
          </el-col>
          <el-col :span="14">
            <div class="class-info">
              <ul>
                <li class="title">{{ courseName }}</li>
                <li></li>
                <!--              <li>课程编码：{{ courseCode }}</li>-->
                <li v-if="beginningTime">培训日期： {{ beginningTime }} 至 {{ endingTime }}</li>
                <li v-if="!isFree">
                  价格：<span class="moneny">¥{{ coursePrice }}</span>
                </li>
                <li v-if="isFree && needUploadEndowments == 1">
                  <span class="moneny">免费</span>
                </li>
                <li v-if="!isFree && endSigningTime">报名截止日期：{{ endSigningTime }}</li>
                <li>
                  <i class="el-icon-user"><span>浏览数：{{ viewCnt }}</span></i>
                  <i class="el-icon-s-custom"><span>报名人数：{{ signupCnt }}</span></i>
                  <i :class="ifCollected
    ? 'collecte el-icon-star-on'
    : 'collecte el-icon-star-off'
    " @click="handleCollection"><span>{{ ifCollected ? "已收藏" : "收藏" }}</span></i>
                </li>
              </ul>
              <div class="btn-wrap">
                <el-button v-if="courseType !== 1" @click="handleBuy" :disabled="isBuging" type="primary">{{ isFree ?
    '立即报名'
    : '立即购买' }}</el-button>
                <el-button v-else @click="goStudy" type="primary">立即学习</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 迁移开始 -->
        <el-row v-else class="class-item">
          <el-col :span="24">
            <div class="es-section course-detail-section">
              <div class="course-detail-top">
                <span class="course-detail-heading">{{ courseName }}</span>
                <span v-if="endowmentsStatus === 1"
                  class="course-endowmentsStatus">该课程需要提交申请表进行审核才能进行学习，请前往个人中心-我的课程进行申请表上传操作</span>
                <span v-if="endowmentsStatus === 2" class="course-endowmentsStatus">您的申请表正在审核中，请耐心等待！</span>
                <span v-if="endowmentsStatus === 4" class="course-endowmentsStatus">您的申请表审核被驳回，请前往个人中心-我的课程重新上传</span>
                <span v-if="(endowmentsStatus === 3 || endowmentsStatus === 0) && chooseStatus === 1"
                  class="course-endowmentsStatus">该课程学习前需要选择选修课时，请前往个人中心-我的课程进行操作</span>
                <!--              <span class="course-detail-code">课程编码：{{ courseCode }}</span>-->
                <ul class="course-operation">
                  <li>
                    <a href="#" class="btn btn-default btn-xs" title="分享" :aria-expanded="false">
                    </a>
                  </li>
                  <li>
                    <a href="#" class="btn btn-default btn-xs"></a>
                  </li>
                </ul>
              </div>
              <section class="course-freelearn-section">
                <div class="row">
                  <div class="col-md-3">
                    <div id="circle-box">
                      <div class="progress">
                        <el-progress type="circle" :percentage="ratio" :width="145" :stroke-width="10" color="#43bc60"
                          :format="setItemText">
                        </el-progress>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <ul class="course-learn-list">
                      <li v-if="!(endingTime && beginningTime2 && endingTime2 && courseType !== 2)">
                        <p class="mb15 color-gray">
                          <i class="iconfont icon-yiwancheng" id="es-icon"></i>已完成
                        </p>
                        <span class="text-16">
                          <span class="color-primary">{{
    learningRateData.finishedPeriodCount
  }}</span>
                          /
                          <span class="mrl">{{
      learningRateData.periodCount
    }}</span>学时
                        </span>
                      </li>
                      <li style="width:30%" v-if="endingTime && beginningTime2 && endingTime2 && courseType !== 2">
                        <p class="mb15 color-gray">
                          <i class="iconfont icon-renwu1" id="es-icon"></i>考试时间
                        </p>
                        <!-- {{beginningTime}}至 -->
                        <span class="text-16"><span style="display:inline-block;margin-bottom: 10px;">{{ endingTime
                            }}</span>{{ beginningTime2 + "-" + endingTime2 }}</span>
                      </li>
                      <li style="width:30%" v-if="courseType === 2">
                        <p class="mb15 color-gray">
                          <i class="iconfont icon-renwu1" id="es-icon"></i>直播时间
                        </p>
                        <!-- {{beginningTime}}至 -->
                        <span class="text-16"><span style="display:inline-block;margin-bottom: 10px;">{{
    learningRateData.startTime }}</span></span>
                      </li>
                      <li v-if="courseType !== 1 && !learningRateData.allFinished">
                        <p class="mb15 color-gray">
                          <i class="iconfont icon-renwu" id="es-icon"></i>下一任务
                        </p>
                        <span class="text-17" @click="
    handleDetail(
      learningRateData.nextPeriodId,
      learningRateData.nextPeriodType,
      learningRateData.learningRate,
      learningRateData.periodStatus,
      learningRateData.correctFinished
    )
    ">{{
    learningRateData.nextPeriodName
  }}</span>
                      </li>
                      <!-- <li v-if="!isFree">
                      <p class="mb15 color-gray">
                        <i class="iconfont icon-renwu" id="es-icon"></i
                        >下一学习任务
                      </p>
                      <span class="text-16"
                        @click="
                          handleDetail(
                            learningRateData.nextPeriodId,
                            learningRateData.nextPeriodType,
                            learningRateData.learningRate,
                            learningRateData.periodStatus
                          )
                        "
                      >{{
                        learningRateData.nextPeriodName
                      }}</span>
                    </li> -->
                    </ul>
                  </div>
                  <div class="col-md-2 pt10" style="display: flex; justify-content: space-around">
                    <el-button v-if="!learningRateData.allFinished" @click="
    handleDetail(
      learningRateData.nextPeriodId,
      learningRateData.nextPeriodType,
      learningRateData.learningRate,
      learningRateData.periodStatus,
      learningRateData.correctFinished
    )
    " class="go-course">{{ btntext }}</el-button>
                    <el-button v-if="this.sessionCourseId !== null &&
    Number(this.courseId) !== this.sessionCourseId
    " @click="handleCourses" type="warning" class="change-replace">
                      确认换课
                    </el-button>
                    <el-button type="success" v-if="learningRateData.allFinished" disabled>
                      直播已结束
                    </el-button>
                  </div>
                </div>
              </section>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="cont mar-bottom">
      <el-row :gutter="20">
        <el-col :span="18">
          <el-card shadow="nerve" class="card">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="介绍" name="first">
                <template v-if="this.courseIntroduction !== null">
                  <div class="info">
                    <div class="content" v-html="courseIntroduction"></div>
                  </div>
                </template>
                <div v-else class="info">暂无介绍</div>
              </el-tab-pane>
              <el-tab-pane label="目录" name="second">
                <template v-if="periodRows.length > 0">
                  <ul v-for="item in periodRows" :key="item.periodId" class="task-list">
                    <li v-if="item.periodType === 0" class="task-item1">
                      <div class="name">
                        <i class="iconfont icon-wenzhangpailie-" @click="handleClickIcon"></i>
                        <i class="iconfont" @click="handleClickIcon" style="display: none">&#xe997;</i>
                        <i class="iconfont" @click="handleClickIcon" style="display: none">&#xe999;</i>
                        <!-- <span>课时 {{ index + 1 }} </span>: -->
                        <span>{{ item.periodName }}</span>
                      </div>
                      <!-- <span class="right-menu color-gray">{{transformDuration(item.periodDuration)}}<i class="iconfont icon-yiwancheng-yiban-02"></i></span> -->
                    </li>
                    <li v-else class="task-item" @click="
    handleDetail(
      item.periodId,
      item.periodType,
      item.learningRate,
      item.periodStatus,
      item.correctFinished,
    )
    ">
                      <div class="name">
                        <i v-if="item.learningRate === 1" class="iconfont icon-weixuanzhongyuanquan-copy"
                          @click="handleClickIcon"></i>
                        <i v-if="item.learningRate === 2" class="iconfont icon-liucheng" @click="handleClickIcon"></i>
                        <i v-if="item.learningRate === 3" class="iconfont icon-yiwancheng" @click="handleClickIcon"></i>
                        <i class="iconfont" @click="handleClickIcon" style="display: none">&#xe997;</i>
                        <i class="iconfont" @click="handleClickIcon" style="display: none">&#xe999;</i>
                        <el-tag v-if="needChoosePeriod && item.isCompulsory === 1 && passPeriodNum !== 0" type="pri">选修
                          {{ item.periodNum }}学时</el-tag>
                        <el-tag v-if="needChoosePeriod && item.isCompulsory === 1 && passPeriodNum === 0"
                          type="pri">选修</el-tag>
                        <el-tag v-if="needChoosePeriod && item.isCompulsory === 0 && passPeriodNum !== 0"
                          type="success">必修 {{ item.periodNum }}学时</el-tag>
                        <el-tag v-if="needChoosePeriod && item.isCompulsory === 0 && passPeriodNum === 0"
                          type="success">必修</el-tag>
                        <span> {{ item.periodName }}</span>

                      </div>
                      <div v-if="item.learningRate == 2 && item.viewTime > 0" class="viewTime">已学习:{{ item.viewTime2 }}
                      </div>
                      <!--                      <span v-if="item.periodDuration!==null && item.periodDuration!==0"-->
                      <!--                            class="right-menu color-gray">{{ transformDuration(item.periodDuration)-->
                      <!--                      }}<i class="iconfont icon-yiwancheng-yiban-02"></i></span>-->
                    </li>
                  </ul>
                </template>
                <div v-else class="noData">暂无数据！</div>
              </el-tab-pane>
              <!-- <el-tab-pane label="资料区" name="third">暂无资料</el-tab-pane> -->
            </el-tabs>
          </el-card>
        </el-col>

        <!-- 迁移页面 -->
        <el-col :span="6">
          <!-- <el-card shadow="nerve" class="card">
            <div class="panel">
              <div class="tit">
                <h3>授课教师</h3>
              </div>
              <div class="body">
                <div class="media-left">
                  <img
                    class="avatar-md"
                    src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg"
                  />
                </div>
                <div class="media-right">
                  <div class="title">网校管理员</div>
                  <div class="content">班级课程管理员</div>
                </div>
              </div>
            </div>
          </el-card> -->
          <!-- 最新学员 -->
          <el-card shadow="nerve" class="card">
            <div class="panel">
              <div class="tit">
                <h3>最新学员</h3>
              </div>
              <div class="body">
                <div v-if="rows.length > 0" class="media-cont">
                  <div class="media-member" v-for="item in rows" :key="item.rows">
                    <div class="avatar">
                      <img class="avatar-md" :src="`${item.avatar}`" />
                      <div class="avatar-nickname">{{ item.userName }}</div>
                    </div>
                  </div>
                </div>
                <div v-else class="no_data">暂无数据</div>
              </div>
            </div>
          </el-card>
          <!-- 动态学员 -->
          <el-card shadow="nerve" class="card mar20">
            <div class="panel">
              <div class="tit">
                <h3>动态学员</h3>
              </div>
              <div class="body">
                <ul v-if="rows2.length > 0" class="dynamic">
                  <li v-for="item in rows2" :key="item.rows2">
                    <p v-if="item.action === 1">
                      {{ item.userName }}<span class="txt999">加入学习</span>
                    </p>
                    <p v-if="item.action === 2">
                      {{ item.userName }}<span class="txt999">开始学习</span>{{ item.periodName }}
                    </p>
                    <p v-if="item.action === 3">
                      {{ item.userName }}<span class="txt999">完成了</span>{{ item.periodName }}
                    </p>
                  </li>
                </ul>
                <div v-else class="no_data">暂无数据</div>
              </div>
            </div>
          </el-card>
        </el-col>
        <!-- 迁移页面结束 -->
      </el-row>
      <!-- 直播课弹窗 -->
      <LiveCode v-if="LiveCodeDialog.show" :live-code-dialog="LiveCodeDialog" />
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderT from "@/components/HeaderT";
import Nav2 from "@/components/Nav2";
import Footer from "@/components/Footer";
import { secondToDate, formatDuring } from "@/utils/index";
import { applyForExchange } from "@/api/myCenter";
import { getFrontStat, getSignupRecent, getUserTrends } from "@/api/course";
import { getRealName } from "@/api/common";
// import { submitOrder } from '@/api/order'
import {
  getClassDetail,
  searchCollectStatus,
  changeCollectStatus,
  signUp,
  getCourseLive
} from "@/api/product.js";
import {
  getPaper,

} from '@/api/task'
import { mapGetters } from "vuex";
import LiveCode from "./components/LiveCode.vue"; // 引入组件

// import { from } from "webpack-sources/lib/CompatSource";
export default {
  name: "ClassDetail",
  components: {
    HeaderT,
    Nav2,
    Footer,
    LiveCode
  },
  data () {
    return {
      getTestData: false,
      isBuging: false, // 下单loading 标记
      activeName: "first",
      ishandleCollection: "true",
      ifCollected: false, // 是否已收藏,
      needChoosePeriod: false,
      visitCount: 0, // 游览用户数
      signUpCount: 0, // 报名用户数
      crumbs: null,
      isPurchased: false, // 是否购买课程
      endowmentsStatus: 0, // 审核状态 0不需要审核 1等待提交凭证  2审核中  3审核成功 4审核失败
      needUploadEndowments: 1, // 是否需要上传凭证 0:需要 1:不需要
      chooseStatus: 0, // 选课状态 0不需要选课 1等待选课 2已选择
      periodRows: [], //课时列表
      courseId: "", //课程Id
      learningRateData: {},
      beginningTime: "", //学习有效期-开始
      endingTime: "", //学习有效期-截至
      beginningTime2: "", //学习有效期-开始
      endingTime2: "", //学习有效期-截至
      ratio: "", // 学习完成百分比
      courseName: "", //课程名称
      courseCode: "", //课程编码
      coursePrice: 0, // 课程价格
      courseIntroduction: null, // 课程简介-富文本
      isFree: null, // 是否免费课程
      coursePic: "", //产品图片
      urlParams: "", //url跳转参数
      passPeriodNum: 0, // 已完成课时
      sessionCourseId: null, // session的课程id

      signupCnt: "", //报名人数
      viewCnt: "", //浏览次数
      rows: [],
      rows2: [],
      needRealname: null, // 观看是否需要实名
      isReal: false, // 实名情况
      type: null, // 类型(0:培训课，1:公开课，2:直播课)
      LiveCodeDialog: {}, //直播弹窗二维码
      courseType: null, // 0:培训课，1:公开课，2:直播课  3:考试课
      startSigningTime: '', // 报名开始时间
      endSigningTime: '', // 报名截止时间
      isSignEnd: null, // 是否已经过了报名截止时间

      btntext: '开始学习',//按钮文案

    };
  },
  computed: {
    ...mapGetters(["userId", "token"]),
  },
  created () {
    console.log('created.....');
    this.type = Number(this.$route.query.type)
    console.log(this.type);
  },
  mounted () {
    console.log(this.userId);
    this.courseId = this.$route.query.id;
    console.log("courseId:", this.courseId);
    this.getDetail(this.courseId);
    if (this.token) {
      this.getRealName();
    }
    // this.getCollectStatus();
    //获取session的课程id
    this.sessionCourseId = JSON.parse(sessionStorage.getItem("courseId"));
    console.log("sessionCourseId:", this.sessionCourseId);
    // 请求课程浏览量和课程人数
    this.getFrontStat();
    this.getSignupRecent();
    this.getUserTrends();
  },

  methods: {
    // getPaper (courseId, periodId) {
    //   const obj = {
    //     // userId: this.userId,
    //     courseId: courseId, //课程id
    //     periodId: periodId //课时章节id
    //   }
    //   // let InterfaceUrl = isFirst ? reTest : getPaper //getPaper首次； reRwar 重考
    //   getPaper(obj).then((res) => {

    //     if (res.data.code != 0) {
    //       this.$message({
    //         type: 'error',
    //         message: res.data.message
    //       })
    //       return false
    //     }
    //   })
    // },
    // 那课程详细信息
    getDetail (ClassId) {
      let interfaceUrl = this.type === 2 ? getCourseLive : getClassDetail
      console.log(ClassId);
      interfaceUrl(ClassId).then((res) => {
        console.log(res);
        // if (res.status === 200) {
        if (res.data.code === 0) {
          this.crumbs = res.data.data.crumbs;
          this.courseName = res.data.data.courseName;
          this.courseCode = res.data.data.courseCode;
          this.isPurchased = res.data.data.isPurchased;
          this.endowmentsStatus = res.data.data.endowmentsStatus;
          this.needUploadEndowments = res.data.data.needUploadEndowments;
          this.chooseStatus = res.data.data.chooseStatus;
          this.refundStatus = res.data.data.refundStatus;
          this.periodRows = res.data.data.periodRows.map((item) => {
            return {
              ...item,
              viewTime2: item.viewTime ? formatDuring(item.viewTime * 1000) : 0
            }
          });
          this.ifCollected = res.data.data.isCollected;
          this.visitCount = res.data.data.visitCount;
          this.signUpCount = res.data.data.signUpCount;
          this.passPeriodNum = res.data.data.passPeriodNum;
          this.beginningTime = res.data.data.beginningTime.split(' ')[0];
          this.needChoosePeriod = res.data.data.needChoosePeriod !== 1;
          this.endingTime = res.data.data.endingTime.split(' ')[0];
          this.beginningTime2 = res.data.data.beginningTime ? res.data.data.beginningTime.split(' ')[1] : '';
          this.endingTime2 = res.data.data.endingTime ? res.data.data.endingTime.split(' ')[1] : '';
          this.learningRateData = res.data.data.learningRateData;
          this.ratio = res.data.data.learningRateData.finishedPeriodCount === 0 ? 0 :
            ((res.data.data.learningRateData.finishedPeriodCount /
              res.data.data.learningRateData.periodCount) * 100).toFixed(0) * 1
            ; // 取整不保留小数
          this.ratio = this.ratio > 100 ? 100 : this.ratio;
          this.coursePrice = res.data.data.coursePrice.toFixed(2);
          this.courseIntroduction = res.data.data.courseIntroduction;
          this.isFree = res.data.data.isFree;
          this.coursePic = res.data.data.coursePic;
          this.needRealname = res.data.data.needRealName;
          this.courseType = res.data.data.courseType; // 课程类型 0:培训课，1:公开课，2:直播课  3:考试课
          this.startSigningTime = res.data.data.startSigningTime,
            this.endSigningTime = res.data.data.endSigningTime,
            this.isSignEnd = res.data.data.isSignEnd

          this.btntext = res.data.data.courseType == 4 ? '开始考试' : '开始学习'

          this.getTestData = true;
          // 处理面包屑
          this.crumbs.forEach((item, index) => {
            if (index === 0) {
              Object.assign(item, {
                urlParams: `/classroom?parent=${item.categoryId}`,
              });
            }
            if (index === 1) {
              Object.assign(item, {
                urlParams: `/classroom?parent=${item.parentId}&second=${item.categoryId}`,
              });
            }
            if (index === 2) {
              Object.assign(item, {
                urlParams: `/classroom?parent=${this.crumbs[0].categoryId}&second=${item.parentId}&three=${item.categoryId}`,
              });
            }
          });
          console.log(this.crumbs);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000,
            onClose: () => {
              this.$router.go(-1);
            },
          });
        }
      });
    },
    //导航点击
    // handleLocation(item,index) {
    //   console.log(item,index);
    //   switch(index){
    //     case 0: // 一级分类
    //       this.urlParams = `/classroom?parent=${item.categoryId}`
    //       /* this.$router.push({
    //         path: '/classroom',
    //         query: {
    //           parent: item.categoryId
    //         }
    //       }) */
    //       break
    //     case 1:
    //       this.urlParams = `/classroom?parent=${item.parentId}&second=${item.categoryId}`
    //       /* this.$router.push({
    //         path: '/classroom',
    //         query: {
    //           parent: this.crumbs[0].categoryId,
    //           second: item.categoryId
    //         }
    //       }) */
    //       break
    //     case 2:
    //       this.urlParams = `/classroom?parent=${this.crumbs[0].categoryId}&second=${item.parentId}&three=${item.categoryId}`
    //       /* this.$router.push({
    //         path: '/classroom',
    //         query: {
    //           parent: this.crumbs[0].categoryId,
    //           second: this.crumbs[1].categoryId,
    //           three: item.categoryId
    //         }
    //       }) */
    //       break
    //   }

    // },
    //查询 状态
    getCollectStatus () {
      console.log(this.$route.query.id);
      const obj = {
        userId: this.userId,
        courseId: this.$route.query.id,
      };
      searchCollectStatus(obj).then((res) => {
        console.log(res);
        // if (res.status === 200) {
        if (res.data.code === 0) {
          console.log(res.data.data);
        }
        // }
      });
    },
    // 改变状态
    handleCollection () {
      console.log("aaa1");
      if (this.token) {
        const obj = {
          userId: this.userId,
          courseId: this.$route.query.id,
          type: this.ifCollected ? 2 : 1, //操作种类1收藏课程2取消收藏
        };
        changeCollectStatus(obj).then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.code === 0) {
              console.log("laili");
              this.$message({
                type: "success",
                message: res.data.message,
                duration: 1 * 1000,
                onClose: () => {
                  this.ifCollected = !this.ifCollected;
                },
              });
            } else {
              this.$message({
                type: "error",
                message: res.data.message
              });
            }
          }
          console.log(res);
        });
      } else {
        this.$message({
          type: 'error',
          message: '使用此功能请先登录！'
        })
      }

    },
    // changeCollectStatus() {

    // },
    handleClick (tab, event) {
      console.log(tab, event);
    },

    // 去做题
    // goCourse() {
    //   this.$router.push({
    //     path: `/task?courseId=${this.courseId}&periodId=${this.nextPeriodId}`,
    //   });
    // },
    //  立即报班
    handleBuy () {
      // let $this = this
      if (!this.userId) {
        let $this = this;
        let msg = this.isFree === true ? '学习' : '购买'
        this.$message({
          type: "error",
          message: `请登录后，${msg}！`,
          duration: 1000,
          onClose () {
            $this.$router.push({
              path: "/login",
            });
          },
        });
      }
      else if (this.isFree) { // 为true 时需要登录 --- 报名以后才能看
        // if (!this.userId) {
        //   let $this = this;
        //   this.$message({
        //     type: "error",
        //     message: "请先登录后报名！",
        //     duration: 3 * 1000,
        //     onClose() {
        //       $this.$router.push({
        //         path: "/login",
        //       });
        //     },
        //   });
        // } else { // 已登录调接口报名关联后才能观看
        const obj = {
          courseId: this.courseId,
        };
        signUp(obj).then((res) => {
          if (res.data.code === 0) {
            console.log(res);
            this.$message({
              type: "success",
              message: res.data.message,
              onClose: () => {
                location.reload(); // 成功刷新页面
              },
            });
          } else {
            this.$message({
              type: "error",
              duration: 1000,
              message: res.data.message,
            });
          }
        });
        // }
      } else if (this.isSignEnd) {
        this.$message({
          type: 'error',
          message: '已过报名时间，无法购买!'
        })
      } else {
        //不是免费，则需要走购买流程
        this.$router.push({
          path: "/confirm/order",
          query: {
            id: this.courseId,
          },
        });
      }
      /* const obj = {
        productName: this.courseName, //产品名（填写课程名）
        productId: this.courseId, //产品Id（填写课程Id）
        productType: 1 , //产品类型 1 课程
        payDetails: this.courseName, //支付方式详情
        payMethod: 1, //支付方式 1 微信支付
        payPrice: this.coursePrice, //支付金额
        // subjectId: 'a' //可选	如使用优惠券等，填写优惠券ID

      }
      this.isBuging = true
      submitOrder(obj).then(res => {
        console.log(res);
      }) */
      /* this.$message({
        type: 'error',
        message: '此功能尚未接入，敬请期待！'
      }) */
    },
    // 点击课时切换图标
    handleClickIcon () { },
    // 进度条文字
    setItemText () {
      let text = "学习进度";
      return text + "\n\n" + this.ratio + "%";
    },
    // 目录点击
    handleDetail (periodId, periodType, learningRate, periodStatus, correctFinished) {
      // courseType 0:培训课，1:公开课，2:直播课  3:考试课
      console.log('courseType:', this.courseType);
      let $this = this;
      if (this.courseType === 1) { // 1:公开课
        this.handleTarget(periodId, periodType, learningRate, correctFinished);
      }/*else if(this.courseType === 2) { // 2:直播课
        this.LiveCodeDialog.show = true;
        this.LiveCodeDialog = {
          show: true,
          title: "课程直播",
          periodId: periodId
        };
      }*/else { // 培训课 与 考试课  直播课
        if (this.userId) { // 登录
          if (this.refundStatus) {
            this.$message({
              type: "error",
              message: "该课程已申请退款，无法学习！",
              duration: 1000,
            });
            return false;
          }
          if (this.endowmentsStatus === 1) {
            this.$message({
              type: "error",
              message: "该课程需要提交申请表进行审核后才能进行学习，请前往个人中心-我的课程进行申请表上传操作",
              duration: 1000,
            });
            return false;
          }
          if (this.endowmentsStatus === 2) {
            this.$message({
              type: "error",
              message: "您的申请表正在审核中，请耐心等待！",
              duration: 1000,
            });
            return false;
          }
          if (this.endowmentsStatus === 4) {
            this.$message({
              type: "error",
              message: "您的申请表审核被驳回，请前往个人中心-我的课程重新上传",
              duration: 1000,
            });
            return false;
          }
          if (this.chooseStatus === 1) {
            this.$message({
              type: "error",
              message: "该课程学习前需要选择选修课时，请前往个人中心-我的课程进行操作",
              duration: 1000,
            });
            return false;
          }
          if (this.isPurchased) {
            if (this.needRealname === true) {
              //需要实名观看
              if (this.isReal) {
                // 已经实名
                if (this.courseType === 2) { // 2:直播课
                  this.LiveCodeDialog.show = true;
                  this.LiveCodeDialog = {
                    show: true,
                    title: "直播详情",
                    periodId: periodId
                  };
                } else {
                  if (periodStatus === 1) { //periodStatus    0不能观看 1可以观看
                    this.handleTarget(periodId, periodType, learningRate, correctFinished);
                  } else {
                    this.$message({
                      type: 'error',
                      message: '该课时未解锁，解锁后才能观看！'
                    })
                  }
                }
              } else {
                this.$message({
                  type: "error",
                  message: "该课程需要先实名认证，请前往个人中心操作！",
                });
              }
            } else {
              if (periodStatus === 1) { //periodStatus    0不能观看 1可以观看
                this.handleTarget(periodId, periodType, learningRate, correctFinished);
              } else {
                this.$message({
                  type: 'error',
                  message: '该课时未解锁，解锁后才能观看！'
                })
              }
            }
          } else {
            this.$message({
              type: "error",
              message: "请您购买后再学习！",
              duration: 1000,
            });
          }
        } else {
          this.$message({
            type: "error",
            message: `请先登录后学习！`,
            duration: 1000,
            onClose () {
              $this.$router.push({
                path: "/login",
              });
            },
          });
          // this.handleTarget(periodId, periodType, learningRate);
        }

      }


      // switch (this.courseType) {
      //   case 0:
      //     console.log('培训课');
      //     //periodType, //课时类型 (1:培训(视频) 2:直播 3:考试)
      //     if (this.isPurchased) {
      //       if (this.needRealname === true) {
      //         //需要实名观看
      //         if (this.isReal) {
      //           // 已经实名
      //           this.handleTarget(periodId, periodType, learningRate);
      //         } else {
      //           this.$message({
      //             type: "error",
      //             message: "该课程需要先实名认证，请前往个人中心操作！",
      //           });
      //         }
      //       } else {
      //         this.handleTarget(periodId, periodType, learningRate);
      //       }
      //     } else {
      //       this.$message({
      //         type: "error",
      //         message: "请您购买后再学习！",
      //         duration: 3 * 1000,
      //       });
      //     }
      //     break;
      //   case 1: // 1:公开课
      //     console.log('公开课');
      //     this.handleTarget(periodId, periodType, learningRate);
      //     break;
      //   case 2: // 2:直播课
      //     this.LiveCodeDialog.show = true;
      //     this.LiveCodeDialog = {
      //       show: true,
      //       title: "课程直播",
      //       periodId: periodId
      //     };
      //     break;
      //   case 3: // 3:考试课
      //     break;
      // } 
    },
    //跳转方法w
    handleTarget (periodId, periodType, learningRate, correctFinished) {
      console.log('periodType:', periodType);
      //如果是考试 correctFinished==false时，提示正在改卷
      if (correctFinished == false && periodType == 3) {
        this.$message({
          type: 'warning',
          message: '您已参加完考试,正在阅卷中'
        })
        return false;
      }
      switch (periodType) {
        case 1:
          this.$router.push({
            path: `/video?courseId=${this.courseId}&periodId=${periodId}`,
          });
          break;
        case 2:
          /* this.$router.push({
            path: `/video?courseId=${courseId}&periodId=${periodId}`
          }) */
          this.$message({
            type: "error",
            message: "敬请期待",
          });
          break;
        case 3:
          // let InterfaceUrl = isFirst ? reTest : getPaper //getPaper首次； reRwar 重考
          getPaper({
            courseId: this.courseId, //课程id
            periodId: periodId //课时章节id
          }).then((res) => {

            if (res.data.code != 0) {
              this.$message({
                type: 'error',
                message: res.data.message
              })
              return false
            } else {
              this.$router.push({
                path: `/task?courseId=${this.courseId
                  }&periodId=${periodId}&isFirst=${learningRate === 1 ? 1 : 0}`, // 首次考试1:是； 0: 否
              });
            }
          })

          break;
        case 4: // 直播课
          this.LiveCodeDialog.show = true;
          this.LiveCodeDialog = {
            show: true,
            title: "直播详情",
            periodId: periodId
          };
          break;

      }
    },
    // 查询是否实名
    getRealName () {
      getRealName().then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.isReal = true; // 已实名
        } else {
          /* this.$message({
            type: 'error',
            message: res.data.message
          }) */
        }
      });
    },
    //获取视频时长
    transformDuration (s) {
      return secondToDate(s);
    },
    //  申请换课
    handleCourses () {
      const obj = {
        preCourseId: this.sessionCourseId, //换课前课程id
        applyCourseId: this.courseId, //期望更换的课程id
        applyReason: "", // 申请原因 可选
      };
      applyForExchange(obj).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.$message({
            type: "success",
            message: `换课${res.data.message}`,
            onClose: () => {
              sessionStorage.removeItem("courseId");
              this.sessionCourseId = JSON.parse(
                sessionStorage.getItem("courseId")
              );
              console.log("sessionCourseId:", this.sessionCourseId);
              // location.reload()
            },
          });
        }
      });
    },
    // 课程前端统计
    getFrontStat () {
      getFrontStat({ courseId: this.courseId }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.signupCnt = res.data.data.signupCnt; //报名人数
          this.viewCnt = res.data.data.viewCnt; //浏览次数
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    // 课程最近学员
    getSignupRecent () {
      getSignupRecent({ courseId: this.courseId }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.rows = res.data.data.rows;
        } /* else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        } */
      });
      console.log(11, this.rows);
    },
    // 学员动态
    getUserTrends () {
      getUserTrends({ courseId: this.courseId }).then((res) => {
        console.log(555, res);
        if (res.data.code === 0) {
          this.rows2 = res.data.data.rows;
        } /* else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        } */
      });
    },
    // 免费课｜公开课 点击立即学习跳转
    goStudy () {
      if (this.courseType !== 1 && this.isFree) { // 免费课 ,需要登录才可以学
        if (!this.token) {
          this.$message({
            type: 'error',
            message: '请登录后在进行学习！'
          })
        }
      } else { //公开课直接看
        this.activeName = 'second'
        this.$message({
          type: 'success',
          message: '请在目录选择课程进行学习！'
        })
      }

    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  height: 100%;
  background-color: #f5f5f5;

  .tab-item {
    p {
      display: flex;
      margin-top: 10px;
    }
  }

  .cont {
    margin: 16px auto 0;
    width: 1170px;

    .class-item {
      background-color: #fff;
      padding: 10px;
      margin-top: 20px;

      /* 迁移页面样式 */
      .course-detail-section {
        position: relative;

        ul {
          list-style: none;

          .course-operation {
            position: absolute;
            list-style-type: none;
            padding-left: 0;

            li {
              float: left;
              margin-right: 10px;

              .course-detail-section .course-operation li>.btn {
                font-size: 14px;
                color: #919191;

                .btn-default:hover {
                  background-color: #f5f5f5;
                }

                .btn {
                  display: inline-block;
                  margin-bottom: 0;
                  font-weight: 400;
                  text-align: center;
                  vertical-align: middle;
                  touch-action: manipulation;
                  cursor: pointer;
                  background-image: none;
                  border: 1px solid transparent;
                  white-space: nowrap;
                  user-select: none;
                }
              }
            }
          }
        }

        .course-detail-top {
          padding-right: 130px;

          .course-detail-heading {
            margin-top: 20px;
            vertical-align: top;
            padding-left: 20px;
            display: flex;
            color: #313131;
            font-size: 24px;
            word-wrap: break-word;
            word-break: break-all;
          }

          .course-endowmentsStatus {
            display: flex;
            margin-top: 10px;
            padding-left: 20px;
            color: red;
          }

          .course-detail-code {
            margin-top: 20px;
            vertical-align: top;
            padding-left: 20px;
            display: flex;
            color: #565050;
            font-size: 14px;
            word-wrap: break-word;
            word-break: break-all;
          }

          .tags {
            float: left;
          }
        }
      }

      .course-freelearn-section {
        background-color: #fafafa;
        margin: 60px 20px;
        padding: 35px 15px;
        height: 70px;

        #circle-box {
          top: -45px;
          left: 75px;
          position: relative;
          width: 160px;
          height: 160px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0 0 2px 2px rgb(235, 235, 235);

          #circle {
            position: absolute;
            z-index: 10;
            left: calc(15px / 2);
            top: calc(15px / 2);
            transform: rotate(-90deg);
          }

          .progress {
            position: absolute;
            left: 7.5px;
            top: 7.5px;
            width: 145px;
            height: 145px;
          }
        }

        /* 已完成 学习有效期  下一学习任务*/
        .course-learn-list {
          list-style: none;
          font-size: 13px !important;
        }

        .course-learn-list>li:first-child {
          width: 33.33%;
        }

        .course-learn-list>li {
          float: left;
          width: 60%;

          .mb15 {
            margin-bottom: 15px !important;
          }

          .color-gray {
            color: #919191;

            .iconfont {
              color: #409eff;
            }
          }

          #es-icon {
            line-height: 1;
            font-size: 16px;
            font-style: normal;
            margin-right: 15px;
          }

          .text-16 {
            font-size: 16px !important;
            margin-left: 20px;

            .color-primary {
              color: #43bc60 !important;
            }

            .mrl {
              margin-right: 20px !important;
            }
          }

          .text-17 {
            font-size: 16px !important;
            margin-left: 20px;
            display: -webkit-box;
            /* 必须值为 -webkit-box */
            -webkit-box-orient: vertical;
            /* 必须值为 vertical */
            overflow: hidden;
            /* 隐藏超出的文本 */
            -webkit-line-clamp: 2;
            /* 显示两行 */


            .color-primary {
              color: #43bc60 !important;
            }

            .mrl {
              margin-right: 20px !important;
            }
          }


          .link-dark {
            cursor: pointer;
            color: #616161 !important;
          }

          a {
            text-decoration: none;
          }
        }

        .row {
          margin-left: -10px;
          margin-right: -10px;

          .col-md-3 {
            float: left;
            width: 25%;
            position: relative;
            min-height: 1px;
            padding-left: 12px;
            padding-right: 12px;
          }

          .col-md-7 {
            float: left;
            width: 50%;
            position: relative;
            min-height: 1px;
            padding-left: 12px;
            padding-right: 12px;
          }

          .col-md-2 {
            float: left;
            width: 18%;
            position: relative;
            min-height: 1px;
            padding-left: 12px;
            padding-right: 12px;

            .btn.btn-lg {
              font-size: 14px;
              padding: 12px 35px;
              line-height: 1.3333333;
              border-radius: 4px;
            }

            .go-course {
              // cursor: pointer;
              background: #43bc60;
              border: 1px #43bc60 solid;
              color: #fff;
            }

            .change-replace {}

            .go-course:hover {
              opacity: 0.8;
            }

            a {
              text-decoration: none;
            }
          }

          .pt10 {
            padding-top: 20px !important;
          }
        }
      }

      .es-section {
        background: #fff;
        margin-bottom: 20px;
        border-radius: 4px;
      }

      section {
        display: block;
      }

      /* 迁移结束 */
      .img-wrap {
        width: 470px;
        height: 270px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .class-info {
        position: relative;
        height: 270px;
        overflow: hidden;

        ul {
          margin-top: 20px;
          overflow: hidden;

          li {
            width: 100%;
            float: left;
            text-align: left;
            margin-bottom: 20px;

            i {
              margin-top: 5px;

              span {
                margin-right: 20px;
                margin-left: 10px;
              }
            }

            .collecte {
              cursor: pointer;
            }

            .moneny {
              color: #fd9271;
              font-size: 20px;
              font-weight: bold;
            }
          }

          .title {
            line-height: 25px;
            max-height: 52px;
            text-align: left;
            color: #444;
            font-size: 25px;
            overflow: hidden;
            margin-bottom: 20px;
          }

          .floatR {
            text-align: right;
          }
        }

        .btn-wrap {
          position: absolute;
          right: 10px;
          bottom: 10px;
          float: right;
        }
      }
    }

    .panel {
      .tit {
        text-align: left;
        line-height: 30px;
        border-bottom: 1px solid #f5f5f5;
      }

      .body {
        padding: 15px 0;

        .media-left {
          display: table-cell;
          vertical-align: top;
          padding-right: 15px;

          .avatar-md {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin: 5px;
          }
        }

        .media-cont {
          display: flex;
          // vertical-align: top;
          flex-wrap: wrap;

          .media-member {
            width: 78px;
            height: 90px;
            overflow: hidden;

            .avatar {
              line-height: 15px;
              width: 78px;

              // padding-right: 10px;
              .avatar-md {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin: 5px auto;
              }

              img {
                margin-left: 10px;
              }
            }
          }

          .avatar-nickname {
            text-align: center;
            font-size: 14px;
            padding-top: 6px;
            color: #999;
            -o-text-overflow: ellipsis;
            width: 70px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .dynamic {
          margin-top: 5px;

          li {
            padding-bottom: 15px;
            text-align: left;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: normal;
            color: #666;

            .txt999 {
              color: #999;
            }
          }
        }

        .media-right {
          display: table-cell;
          vertical-align: top;
          font-size: 14px;

          .title {
            margin: 10px 0 10px 0;
            text-align: left;
          }

          .content {
            color: #919191;
          }
        }

        .no_data {
          text-align: center;
          font-size: 14px;
          color: #999;
        }
      }
    }

    .mar20 {
      margin-top: 20px;
    }

    .card {
      .info {
        text-align: center;

        img {
          border: 1px #f00 solid;
          width: 80% !important;
          margin: 0 auto;
          text-align: center;
          height: auto;
        }

        .content {
          // text-align: left;
          margin-top: 20px;
          color: #333;

          /deep/ p {
            text-align: left;
            margin: 10px 0;
            line-height: 1.5;
          }

          /deep/ img {
            width: 90% !important;
            height: auto;
          }
        }
      }
    }
  }

  /* 迁移页面样式 */
  .mar-bottom {
    margin-bottom: 20px;

    .task-list {
      background-color: #f5f5f5 !important;
      height: 52px;
      line-height: 52px;
      margin-top: 10px;

      // cursor: pointer;
      .task-item,
      .task-item1 {
        width: 100%;
        position: relative;

        .name {
          float: left;
          width: 100%;
          margin-left: 20px;
          text-decoration: none;
          color: #616161;
          text-align: left;
        }

        .viewTime {
          position: absolute;
          top: 0px;
          right: 0px;
          margin-right: 20px;
          color: #999;
          font-size: 14px;
        }

        .right-menu {
          position: absolute;
          top: 0px;
          right: 0px;
          margin-right: 20px;
          color: #666;
        }

        .iconfont {
          color: #409eff;
          padding: 10px;
        }
      }

      .task-item:hover {
        cursor: pointer;

        .name {
          color: #b7090a;
        }
      }

      .task-item1 {
        .name {
          span {
            font-weight: bold;
          }
        }
      }

      .task-list-md {
        .task-item {
          margin-bottom: 10px;
          position: relative;
          // text-align: left;
          float: left;

          .name {
            float: left;
            margin-left: 20px;
            text-decoration: none;
            color: #616161;
            text-align: left;
            border: 1px #f00 solid;
          }

          .right-menu {
            float: right;
            margin-right: 20px;
            color: #333;
          }
        }
      }
    }

    .noData {
      padding: 10px 0;
      text-align: center;
    }

    /* 迁移页面样式结束 */
  }

  .course-item {
    margin-top: 30px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 4px;

    .media {
      position: relative;
      background-color: #f5f5f5;
      padding: 10px;

      .media-body {
        display: table-cell;
        vertical-align: top;

        .title {
          margin-right: 72px;
          font-size: 18px;
          margin-bottom: 15px;

          a {
            cursor: pointer;
            color: #333;
            text-decoration: none;
            outline: none;
          }
        }
      }

      .media-left {
        padding-right: 35px;
        display: table-cell;
        vertical-align: top;

        img {
          width: 160px;
          height: auto;
          border: none;
        }
      }
    }
  }

  .layui-card-body {
    position: relative;
    padding: 10px 15px;
    line-height: 24px;
    width: 270px;

    .pdt10 {
      padding-top: 10px !important;
    }

    .mb20 {
      margin-bottom: 20px !important;

      .layui-col-md2 {
        position: relative;
        display: block;
        box-sizing: border-box;
        float: left;
        width: 16.67%;
      }
    }

    .pic {
      img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      a {
        cursor: pointer;
        color: #333;
        text-decoration: none;
        outline: none;
        width: 40px;
        height: 40px;
        display: flex;
        float: left;
      }
    }
  }

  /deep/ .el-progress__text {
    white-space: pre; //使百分号与所添加文字换行
  }
}
</style>